import { useState, useEffect } from "react";
import { Get } from "../utils/Remote";
import { NavItem, Action } from "./Routing";
import "../css/navbar.css";

export default function Navbar({ pages }) {
  const [state, setState] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    Get("https://griep.dev/api/state", setState);
  }, []);

  useEffect(() => {
    if (isAuthorized(state)) {
      Get("https://griep.dev/api/user/info", setUserInfo);
    }
  }, [state]);

  return (
    <nav>
      <div className="nav-column">Marcel Griep{"\u00A9"}</div>
      <div className="nav-column col-center">
        {pages.map((entry) => (
          <NavItem target={entry.target} className="nav-item">
            {entry.title}
          </NavItem>
        ))}
      </div>
      <div className="nav-column col-right">
        <Menu userInfo={userInfo} state={state}>
          <Action
            target={"https://griep.dev/api/reset-password/identity/start"}
            body={state}
          >
            Passwort zurücksetzen
          </Action>
          <Action target={"https://griep.dev/api/logout"}>Logout</Action>
        </Menu>
      </div>
    </nav>
  );
}

function Menu({ children, userInfo, state }) {
  const [menuVisible, setMenuVisible] = useState(false);

  function toggleVisibility() {
    setMenuVisible(!menuVisible);
  }

  return (
    <>
      {isAuthorized(state) ? (
        <UserDropdown
          toggleVisibility={toggleVisibility}
          userInfo={userInfo}
          menuVisible={menuVisible}
          children={children}
        />
      ) : (
        <Login />
      )}
    </>
  );
}

function UserDropdown({ toggleVisibility, userInfo, menuVisible, children }) {
  const classStr = "menu flex flex-spacing flex-right flex-column";
  const showMenu = classStr + (menuVisible ? " expand" : " collapse");
  return (
    <>
      <button className="menu-button" onClick={toggleVisibility}>
        {userInfo.display_name}
      </button>
      <div className="relative">
        <div className={showMenu}>{children}</div>
      </div>
    </>
  );
}

function Login() {
  return (
    <NavItem target={"https://auth.griep.dev"} className="nav-item menu-button">
      Login
    </NavItem>
  );
}

function isAuthorized(state) {
  return state && state.authentication_level > 0;
}
