const newLine = "new-line";
const title = "title";
const category = "category";

export function Title({ children }) {
  return <h1 className={join(title, newLine)}>{children} </h1>;
}

export function SecondaryTitle({ children }) {
  return <h2 className={join(title, newLine)}>{children} </h2>;
}

export function Category({ children }) {
  return <h3 className={category}>{children} </h3>;
}

function join(...items) {
  return items.join(" ");
}
