import { Category, SecondaryTitle, Title } from "../components/Components";
import "../css/about.css";

export default function AboutMe() {
  return (
    <div>
      <div className="flex flex-spacing flex-column">
        <div>
          <Title>SOFTWARE{"\n"}DEVELOPER</Title>
          <img
            src="https://static.griep.dev/portrait.jpg"
            alt="Marcel Griep"
            className="portrait"
          />
        </div>
        <div className="flex flex-spacing flex-right">
          <SecondaryTitle>MARCEL{"\n"}GRIEP</SecondaryTitle>
        </div>
      </div>
      <div>
        <Category>SKILLS</Category>
        <ul>OOP: Java / C# / Python / JS</ul>
        <ul>SQL: MySQl / SQLServer / PostgreSql / SQLite</ul>
        <Category>LANGUAGES</Category>
        <ul>German / English (C1) / Spanish (A1 - improving)</ul>
        <Category>OTHER</Category>
        <ul>Git / Docker / Project management / Factorio 100%</ul>
      </div>
    </div>
  );
}
