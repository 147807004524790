export function PostAndForget(target, body = {}) {
  console.log(JSON.stringify(body));

  return fetch(target, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).ok;
}

export async function Get(target, consumer) {
  fetch(target)
    .then((response) => response.json())
    .then((json) => consumer(json.data));
}
