import { PostAndForget } from "../utils/Remote";

const CURRENT_IDENT_CLASS = "current";

export function NavItem({ target, children, className = "", attributes }) {
  let isCurrent = target === window.location.pathname;

  return (
    <a
      href={target}
      {...attributes}
      className={className + (isCurrent ? " " + CURRENT_IDENT_CLASS : "")}
    >
      {isCurrent ? children : children}
    </a>
  );
}

export function Action({ target, body, children, attributes }) {
  return (
    <a href="/" onClick={() => PostAndForget(target, body)} {...attributes}>
      {children}
    </a>
  );
}
