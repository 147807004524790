import { Category, Title } from "../components/Components";

export default function Contact() {
  return (
    <div>
      <Title>CONTACT</Title>
      <Category>
        <a href="mailto:marcel@griep.dev">mail.</a>
      </Category>
      <Category>
        <a href="https://www.linkedin.com/in/marcel-griep-325220288/">
          linkedIn.
        </a>
      </Category>
      <Category>
        <a href="https://www.xing.com/profile/Marcel_Griep2">xing.</a>
      </Category>
    </div>
  );
}
