import { useEffect, useState } from "react";
import "../css/background.css";

const WINDOW_WIDTH = window.innerWidth;
const WINDOW_HEIGHT = window.innerHeight;
const SCROLL_WITDTH = document.body.scrollWidth;

class Circle {
  constructor(x, y, r, p) {
    this.x = x;
    this.y = y;
    this.r = r;
    this.p = p;
  }
}

export function BackgroundImage() {
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    setScrollHeight(document.body.scrollHeight);
  }, [document.body.scrollHeight]);

  const circles = [];

  if (scrollHeight > 0) {
    const numCircles = Math.floor(Math.random() * 3);
    for (let i = 0; i <= numCircles; i++) {
      circles.push(new Circle(getX(), getY(), getRadius(), getColorIndex()));
    }
  }

  return (
    <svg
      id="background"
      className="background"
      style={{ height: Math.max(2400, scrollHeight) }}
    >
      <defs>
        <filter
          id="blur"
          width={Math.max(window.screen.width, SCROLL_WITDTH)}
          height={Math.max(window.screen.height, scrollHeight)}
          x="-50"
          y="-50"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="100" />
        </filter>
      </defs>
      {circles.map((c) => (
        <circle
          cy={c.y}
          cx={c.x}
          r={c.r}
          className={"palette-" + c.p}
          filter="url(#blur)"
        />
      ))}
    </svg>
  );
}

function getX() {
  let rootWidth = window.document.getElementsByTagName("main")[0].scrollWidth;
  let minX = WINDOW_WIDTH / 2 - rootWidth / 2;
  return minX + Math.floor(Math.random() * rootWidth);
}

function getY() {
  return Math.floor(Math.random() * WINDOW_HEIGHT);
}

function getRadius() {
  const minRadius =
    window.document.getElementsByTagName("main")[0].scrollWidth * 0.25;

  const maxRadiusOffset = minRadius * 2;
  return minRadius + Math.floor(Math.random() * maxRadiusOffset);
}

function getColorIndex() {
  return Math.floor(Math.random() * 3) + 1;
}
