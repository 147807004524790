import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AboutMe from "./pages/AboutMe";
import { BackgroundImage } from "./components/Background";

class PageEntry {
  constructor(title, component, target = "/" + title) {
    this.title = title;
    this.component = component;
    this.target = target;
  }
}

export default function LandingPage() {
  const pages = [
    new PageEntry("Home", <Home />, "/"),
    new PageEntry("About", <AboutMe />),
    new PageEntry("Contact", <Contact />),
  ];

  const path = window.location.pathname;
  const page = pages.filter((entry) => path === entry.target);

  return (
    <>
      <Navbar pages={pages}></Navbar>
      <main>{page.length > 0 && page[0].component}</main>
      <BackgroundImage />
    </>
  );
}
