import { Category, Title } from "../components/Components";
import { NavItem } from "../components/Routing";
export default function Home() {
  return (
    <div className="flex flex-spacing flex-column">
      <Title>GRIEP.DEV</Title>
      <Category>
        <NavItem target={"https://files.griep.dev"}>files.</NavItem>
      </Category>
      <Category>
        <NavItem target={"https://code.griep.dev"}>code.</NavItem>
      </Category>
      <Category>
        <NavItem target={"/dashboard"}>dashboard.</NavItem>
      </Category>
    </div>
  );
}
